import { Box, TextField } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
import React, { useRef, useState } from 'react';

export default function PromocodeFeild({ setPromocodeDiscount }) {
  const promocodeRef = useRef(null);
  const [errorHelper, setErrorHelper] = useState(null);
  const checkPromocode = () => {
    fetch(`${process.env.GATSBY_LK_URL}/api/promocodes`, {
      method: 'post',
      body: JSON.stringify({ code: promocodeRef.current }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result.discount) {
          throw new Error('no promocode');
        }
        setErrorHelper('');
        setPromocodeDiscount(result.discount);
      })
      .catch((e) => setErrorHelper('Промокод не найден'));
  };

  return (
    <Box sx={{ display: 'flex', gap: 3, mt: 3 }}>
      <TextField
        color="primary"
        size="small"
        placeholder="Введите промокод"
        helperText={errorHelper}
        label="Введите промокод"
        onChange={(e) => (promocodeRef.current = e.target.value)}
        sx={{
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: { xs: -18, lg: -16 },
            fontSize: { xs: 12, lg: 14 },
          },
        }}
      />
      <Button
        variant="outlined"
        size="large"
        onClick={checkPromocode}
        sx={{ height: 'fit-content' }}
      >
        применить
      </Button>
    </Box>
  );
}
