import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Box,
  ListSubheader,
  Checkbox,
  Skeleton,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useLocation, navigate } from '@reach/router';
import PhoneInput from 'react-phone-input-2';
import { Button, Link } from 'gatsby-theme-material-ui';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ModalBlock from '../../shared/ModalBlock/ModalBlock';
import Section from '../../shared/Section/Section';
import PercentIcon from '../../kit/icons/PercentIcon';
import PaymentMethodsBlock from '../../shared/PaymentMethodsBlock/PaymentMethodsBlock';
import { Controller, useForm } from 'react-hook-form';
import PromocodeFeild from '../../shared/PromocodeFeild/PromocodeFeild';
import TinfoffFields from './TinfoffFields';
import {
  excludeProducts,
  QRCODE_DISCOUNT,
  streamName,
  programsNames,
  programsOrder,
  locationsNames,
  locationsOrder,
} from './constants';
import styles from './styles';
import { validatePhone } from '../../helpers/lib';
import {
  getGaUidFromCookie,
  getUtmFromCookie,
  getYmUidFromCookie,
} from '../../helpers/utmCookie';
import { apiMyLead, apiPayment } from '../../app/constants/urls';
import QRCodePaymentModal from './QRCodePaymentModal';
import { sendYandexMetrikaEvent } from '../../helpers/yandexMetrika';

const validateName = (name) => name.trim().length > 0;
const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const renderProgramOptions = (programs) => {
  const programOptions = [];
  let addedSubheader = false;

  programs.forEach(({ programName, id }) => {
    if (id.endsWith('_basics') && !addedSubheader) {
      programOptions.push(
        <ListSubheader
          key="subheader-1"
          sx={{ p: 0, ml: -0.5, height: 28, lineHeight: '1rem' }}
        >
          Подготовительные программы
        </ListSubheader>,
      );
      addedSubheader = true;
    }
    programOptions.push(
      <MenuItem
        key={id}
        value={id}
        sx={{
          fontSize: 14,
          '&.Mui-selected': { p: '4px !important' },
        }}
      >
        {programName}
      </MenuItem>,
    );
  });

  return programOptions;
};

const renderLocationOptions = (locations) =>
  locations.map(({ locationName, id }) => (
    <MenuItem key={id} value={id} sx={{ fontSize: 14 }}>
      {locationName}
    </MenuItem>
  ));

function PaymentPageBlock({ prices = [], ymEvents = {} }) {
  const location = useLocation();

  const {
    register,
    setValue,
    trigger,
    formState: { errors, dirtyFields },
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm();

  const [state, setState] = useState({
    stream: {},
    products: [],
    programs: [],
    locations: [],
    isQRCodeModalOpen: false,
    activeMethod: null,
    promocodeDiscount: 0,
    agreeCheckbox: false,
    queryParams: Object.fromEntries(new URLSearchParams(location.search)),
    loading: false,
  });
  const {
    stream,
    products,
    programs,
    locations,
    isQRCodeModalOpen,
    activeMethod,
    promocodeDiscount,
    agreeCheckbox,
    queryParams,
    loading,
  } = state;

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const formRef = useRef(null);
  const isPrepare = stream?.program?.includes('basics');
  const discountDate = new Date(stream?.discountDate);
  discountDate.setDate(discountDate.getDate() + 1);
  const isDiscountExpired = discountDate.getTime() < Date.now();
  const discountPrice = !isDiscountExpired && stream.pricesWithDiscount;
  const actualPrice = Math.floor(
    (discountPrice ||
      (stream.productPriceFull &&
        +stream.productPriceFull.split(' ').join(''))) *
      (1 - promocodeDiscount / 100),
  );
  const qrCodePrice =
    !isPrepare &&
    !discountPrice &&
    Math.floor(activeMethod * actualPrice * (1 - QRCODE_DISCOUNT));
  const noPromocode =
    !isPrepare || !!discountPrice || (queryParams.s && !isNaN(+queryParams.s));

  const handleProgramChange = (event) => {
    setValue(event.target.name, event.target.value);
    handleStateChange(
      'stream',
      products.find(
        (product) =>
          product.program === event.target.value &&
          product.location === 'online',
      ),
    );
    navigate(location.pathname, { replace: true });
    handleStateChange('promocodeDiscount', 0);
  };
  const handleLocationChange = (event) => {
    setValue(event.target.name, event.target.value);
    handleStateChange(
      'stream',
      products.find(
        (product) =>
          product.program === stream.program &&
          product.location === event.target.value,
      ),
    );
  };

  function parseInput(input) {
    const parts = input.split('.');
    const domain = parts[0];
    const subdomain = parts[1];

    const programMapping = {
      js: 'js',
      ds: 'ds',
      ux: 'design',
      design: 'design',
    };
    const basicsMapping = {
      js: 'js_basics',
      ds: 'ds_basics',
      ux: 'design_basics',
      design: 'design_basics',
    };
    let program =
      subdomain === 'prepare'
        ? basicsMapping[domain] || 'unknown'
        : programMapping[domain] || 'unknown';
    let location = subdomain === 'prepare' ? 'online' : subdomain;
    return { program, location };
  }

  useEffect(() => {
    if (programs.length && queryParams.leartype) {
      const { program, location } = parseInput(queryParams.leartype);
      const stream = {
        ...products.find(
          (product) =>
            product.program === program && product.location === location,
        ),
      };
      handleStateChange('stream', {
        ...stream,
        productPriceFull:
          (!isNaN(queryParams.s) && queryParams.s) || stream.productPriceFull,
        pricesWithDiscount: queryParams.s ? '' : stream.pricesWithDiscount,
      });
    }
  }, [queryParams, programs]);

  useEffect(() => {
    const availableProducts = prices
      .filter(
        ({ productName }) =>
          !excludeProducts.some((item) => item === productName),
      )
      .map((priceItem) => {
        const productPriceName = priceItem.productName;
        const isPrepare = productPriceName.includes('prepare');
        const programName = productPriceName.split('_').at(-1);
        const location = isPrepare
          ? 'online'
          : productPriceName.split('_').slice(0, -1).join('_');
        const paymentName = `${programName}.${location}.ru`;
        const productName = streamName[`${productPriceName}`];
        const program = `${programName}${isPrepare ? '_basics' : ''}`;
        const tags = [`${programName} ${location}`];
        return {
          ...priceItem,
          paymentName,
          productName,
          program,
          location,
          tags,
        };
      });
    handleStateChange('products', availableProducts);
  }, [prices]);
  useEffect(() => {
    const stream =
      products.find((product) => product.location === 'online') || {};
    handleStateChange('stream', stream);
    const programs = Array.from(new Set(products.map((item) => item.program)))
      .map((program) => ({ id: program, programName: programsNames[program] }))
      .sort((a, b) => {
        return programsOrder.indexOf(a.id) - programsOrder.indexOf(b.id);
      });
    setValue('program', stream.program);
    handleStateChange('programs', programs);
  }, [products]);
  useEffect(() => {
    const locations = Array.from(
      new Set(
        products
          .filter((product) => product.program === stream.program)
          .map((item) => {
            return item.location;
          }),
      ),
    )
      .map((location) => ({
        id: location,
        locationName: locationsNames[location],
      }))
      .sort((a, b) => {
        return locationsOrder.indexOf(a.id) - locationsOrder.indexOf(b.id);
      });
    setValue('location', stream.location);
    handleStateChange('locations', locations);
  }, [stream]);

  const handleSubmit = (e) => {
    e.preventDefault();
    window?.pay && pay(e.target);
    handleStateChange('loading', true);
  };
  const sendForm = async () => {
    const { email, fullName, location, phone, program } = getValues();
    try {
      await fetch(apiMyLead, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: fullName,
          email,
          howToContact: phone,
          refererName: '',
          phone,
          telegram: '',
          clientId: window?.ga?.getAll()[0].get('clientId'),
          type: 'заявка',
          targetPage: '/payment/',
          ym_uid: getYmUidFromCookie(),
          ga_uid: getGaUidFromCookie(),
          ym_counter: '50001859',
          leadPage: 'Страница оплаты',
          program: `${programsNames[program]} ${locationsNames[location]}`,
          // tags: [],
          eventName: activeMethod ? 'Оплата по QR-коду' : 'Оплата картой',
          leadBlock: 'Стоимость',
          leadAction: 'Оплатить',
          howToContact: 'Телефон',
          utm: getUtmFromCookie(),
        }),
      });

      sendYandexMetrikaEvent(ymSubmitEventType);
      sendMyTargetEvent(myTargetSubmitEventType);
      if (ymSubmitExtraEventType) {
        sendYandexMetrikaEvent(ymSubmitExtraEventType);
      }

      // setIsSent(true);
    } catch (e) {
      // setIsError(true);
      console.error(e.message);
    } finally {
      // setIsLoading(false);
    }
  };
  const getPaymentLink = async () => {
    handleStateChange('loading', true);
    const { email, fullName, location, phone, program } = getValues();
    console.log(email);

    let link;
    try {
      const response = await fetch(apiPayment, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: fullName,
          email,
          // howToContact: phone,
          // refererName: '',
          // phone,
          // telegram: '',
          clientId: window?.ga?.getAll()[0].get('clientId'),
          type: 'заявка',
          targetPage: '/payment/',
          ym_uid: getYmUidFromCookie(),
          ga_uid: getGaUidFromCookie(),
          ym_counter: '50001859',
          leadPage: 'Страница оплаты',
          program: `${programsNames[program]} ${locationsNames[location]}`,
          // tags: [],
          eventName: activeMethod ? 'Оплата по QR-коду' : 'Оплата картой',
          leadBlock: 'Стоимость',
          leadAction: 'Оплатить',
          howToContact: 'Телефон',
          utm: getUtmFromCookie(),

          amount: actualPrice * 100,
          recieptItemName: `Образовательные услуги по курсу ${
            programsNames[stream.program]
          } ${locationsNames[stream.location]}`,
          groupCourse: program,
          // type: paymentType,
        }),
      });

      // setIsSent(true);
      link = await response.text();
      console.log(link);
    } catch (e) {
      // setIsError(true);
      handleStateChange('loading', false);
      console.error(e.message);
    } finally {
      // setIsLoading(false);
      return link;
    }
  };
  const initPay = async () => {
    // handleStateChange('loading', true);
    sendForm();
    // if (activeMethod !== 1) {
    //   const link = await getPaymentLink();
    //   if (link) {
    //     navigate(link);
    //   }
    // }
    handleStateChange('isQRCodeModalOpen', !!activeMethod);
  };

  return (
    <>
      <Section bg="payment-page" customStyles={{ paddingBottom: 0, mt: 2.5 }}>
        <Box
          ref={formRef}
          component="form"
          id="order"
          className="pay-form"
          name="TinkoffPayForm"
          method="GET"
          onSubmit={handleSubmit}
          sx={styles.form}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 484,
              p: { xs: 0, lg: (theme) => theme.spacing(0, 7.5, 4, 0) },
            }}
          >
            <Typography variant="desktopH2" color={'kit.text.main'}>
              Оплата
            </Typography>
            <FormControl variant="standard" sx={{ mt: 4 }} key={stream.program}>
              <InputLabel>
                <Typography color="kit.text.main" variant="desktopP3">
                  Выберите направление
                </Typography>
              </InputLabel>
              <Select
                name="program"
                {...register('program')}
                value={stream.program}
                onChange={handleProgramChange}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                label="Выберите направление"
              >
                {renderProgramOptions(programs)}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ mt: 4 }}
              key={stream.location}
            >
              <InputLabel>
                <Typography color="kit.text.main" variant="desktopP3">
                  Выберите формат
                </Typography>
              </InputLabel>
              <Select
                name="location"
                {...register('location')}
                disabled={locations.length < 2}
                value={stream.location}
                onChange={handleLocationChange}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                label="Выберите формат"
              >
                {renderLocationOptions(locations)}
              </Select>
            </FormControl>
            <Typography variant="desktopP1" mt={4} color={'kit.text.main'}>
              Контактные данные
            </Typography>
            <Box mt={1}>
              <TextField
                label="Ф.И.О."
                name="fullName"
                {...register('fullName', { validate: validateName })}
                error={!!errors.fullName}
                helperText={errors.fullName ? 'Поле не может быть пустым' : ''}
                onBlur={() => trigger('fullName')}
                onChange={(e) => {
                  setValue('fullName', e.target.value);
                  trigger('fullName');
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    fontSize: 14,
                  },
                }}
              />
              <TextField
                label="Email"
                name="email"
                {...register('email', { validate: validateEmail })}
                error={!!errors.email}
                helperText={
                  errors.email && dirtyFields.email
                    ? 'Некорректный e-mail'
                    : errors.email
                      ? 'Поле не может быть пустым'
                      : ''
                }
                sx={{
                  mt: 3,
                  '& .MuiInputLabel-root': {
                    fontSize: 14,
                  },
                }}
                onChange={(e) => {
                  setValue('email', e.target.value, { shouldDirty: true });
                  trigger('email');
                }}
              />
              <Box sx={styles.phoneWrapper}>
                <Controller
                  render={() => (
                    <PhoneInput
                      inputClass={errors.phone ? 'danger' : ''}
                      placeholder="+7 (999) 999-99-99"
                      inputRef={register}
                      inputStyle={styles.inputTelephone}
                      dropdownStyle={styles.dropDown}
                      id={'phone'}
                      specialLabel=""
                      autoComplete="phone"
                      country="ru"
                      inputProps={{
                        autoFocus: false,
                        name: 'phone',
                      }}
                      onChange={(value, country, e, formatedValue) => {
                        setValue('phone', formatedValue);
                        if (!validatePhone(value, formatedValue, country)) {
                          setError('phone', {
                            message: 'Введите корректный номер',
                          });
                        } else {
                          clearErrors('phone');
                        }
                      }}
                    />
                  )}
                  enableLongNumbers
                  defaultValue=""
                  name={'phone'}
                  control={control}
                  rules={{
                    required: 'Введите номер телефона',
                  }}
                />
                <Typography
                  component="span"
                  color="kit.text.error"
                  style={styles.errorMessage}
                >
                  {errors.phone && errors.phone.message}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mt: 5 }}
            >
              <Checkbox
                onChange={() => {
                  if (!agreeCheckbox) {
                    trigger();
                  }
                  handleStateChange('agreeCheckbox', !agreeCheckbox);
                }}
              />
              <Typography variant="desktopP3" color={'kit.text.main'}>
                Я даю согласие на обработку персональных данных и соглашаюсь с{' '}
                <Link
                  variant="desktopP3"
                  color={'kit.text.h'}
                  href="/contract_agreements/"
                >
                  условиями договора
                </Link>{' '}
                и{' '}
                <Link
                  variant="desktopP3"
                  color={'kit.text.h'}
                  href="/docs/confidentiality_agreement.pdf"
                >
                  политикой конфиденциальности
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: 438 }}>
            <Box
              sx={(theme) => ({
                backgroundColor: 'kit.background.gray',
                borderRadius: '16px',
                borderBottomRightRadius: { xs: 0, lg: 16 },
                borderBottomLeftRadius: { xs: 0, lg: 16 },
                p: { xs: theme.spacing(3, 2, 4), lg: theme.spacing(3, 4, 4) },
                minWidth: { xs: '100%', xl: 378 },
                maxWidth: 478,
                mx: -2,
                mt: { xs: 3, lg: 0 },
              })}
            >
              <Typography
                variant="desktopH4"
                color={'kit.text.main'}
                letterSpacing={-0.75}
              >
                Итого
              </Typography>
              {!isNaN(qrCodePrice || actualPrice) ? (
                <Typography
                  component={'span'}
                  variant="desktopH2"
                  color={'kit.text.main'}
                  letterSpacing={-0.75}
                  mt={1}
                >
                  {(qrCodePrice || actualPrice).toLocaleString('ru-RU')} ₽
                  {!!qrCodePrice && '/'}
                </Typography>
              ) : (
                <Skeleton sx={{ fontSize: 36, maxWidth: 120 }} />
              )}
              {!!qrCodePrice && (
                <Typography
                  component={'span'}
                  variant="desktopH4"
                  sx={{
                    textDecoration: 'line-through',
                    mt: 1,
                    letterSpacing: -0.75,
                    color: 'kit.text.secondary',
                  }}
                >
                  {actualPrice.toLocaleString('ru-RU')} ₽
                </Typography>
              )}
              <Typography
                variant="desktopH4"
                color={'kit.text.main'}
                letterSpacing={-0.75}
                mt={3}
              >
                Выберите способ оплаты
              </Typography>
              <PaymentMethodsBlock
                noDiscount={isPrepare}
                active={activeMethod}
                setActive={(val) => handleStateChange('activeMethod', val)}
              />
              {!isPrepare && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                    mt: 3,
                    alignItems: 'center',
                  }}
                >
                  <PercentIcon />
                  <Box>
                    <Typography
                      component={'span'}
                      variant="desktopP3"
                      color={'kit.text.secondary'}
                      lineHeight={1.4}
                      letterSpacing={'-0.5px'}
                    >
                      Получите скидку при оплате
                    </Typography>{' '}
                    <Typography
                      component={'span'}
                      variant="desktopP3"
                      color={'kit.text.secondary'}
                      lineHeight={1.4}
                      letterSpacing={'-0.5px'}
                      fontWeight={700}
                    >
                      QR-коду
                    </Typography>
                  </Box>
                </Box>
              )}
              {!noPromocode && (
                <PromocodeFeild
                  setPromocodeDiscount={(val) =>
                    handleStateChange('promocodeDiscount', val)
                  }
                />
              )}
              {activeMethod !== null && (
                <Button
                  type={activeMethod === 0 ? 'submit' : 'button'}
                  onClick={initPay}
                  disabled={
                    !agreeCheckbox ||
                    Object.keys(errors).length !== 0 ||
                    loading
                  }
                  variant="kitPrimary"
                  size="large"
                  sx={{ width: '100%', mt: 4, maxHeight: 52 }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        width: '28px !important',
                        height: '28px !important',
                      }}
                    />
                  ) : (
                    'Перейти к оплате'
                  )}
                </Button>
              )}
            </Box>
          </Box>
          <TinfoffFields
            fullName={getValues().fullName}
            price={actualPrice || 0}
            description={`Образовательные услуги по курсу ${
              programsNames[stream.program]
            } ${locationsNames[stream.location]}`}
          />
        </Box>
      </Section>
      <ModalBlock
        isFreeForm
        showHeader
        isOpen={isQRCodeModalOpen}
        handleClose={() => handleStateChange('isQRCodeModalOpen', false)}
      >
        <QRCodePaymentModal
          qrCodePrice={qrCodePrice}
          actualPrice={actualPrice}
          closeHandler={() => {
            handleStateChange('isQRCodeModalOpen', false);
          }}
          course={stream.program}
          groupCity={stream.location}
          email={getValues().email}
        />
      </ModalBlock>
    </>
  );
}

export default PaymentPageBlock;
